/* #map {
    background-color: transparent !important;
  } */


  .app-sidebar {
    border-right: 2px solid rgba(255, 255, 255, .1) ;
  }
  .welcomePage {
    display: flex;
    height: 80vh;
  }
  .socialLogin .nsm7Bb-HzV7m-LgbsSe {
    border: none !important;
  }
  .circle {
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%; /* the magic */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 auto 40px;
  }
  

  .agrntChat .apexcharts-legend {
    width: 80% !important;
    left: 50% !important;
    bottom: 2% !important; 
    transform: translateX(-50%) !important;
  }

.threatClass .apexcharts-canvas {
  position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.aliasEditButton,.aliasCancelButton,.googleLogin,.mapIpClick{
  cursor: pointer;
}
.mapIpClick {
  cursor: pointer;
  color: #3dd2a5;

}
.aliasEditButton:hover {
  color: #3dd2a5;
}
.aliasCancelButton:hover {
  color: red;
}

.agentDownLoadButton {
  font-size: 18px;
  height: 40px;
  border: #3dd2a5 solid 1px ;
  border-radius: 10px !important;

}

.notificationIcon{
  background: var(--bs-theme);
  margin-top: -18px;
  border-radius: 50%;
  padding: 2px;
  margin-left: -7px;
  color: var(--bs-theme-color);
}

.agentDownLoadButtonIndividual {
  font-size: 16px;
  font-weight: 900 !important;
  border-radius: 5px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #3dd2a5;
  cursor: pointer;
}
.agentDownLoadButtonIndividual div{
  margin-right: 10px;
}

/* .tootTipClass {
  background-color: red !important;
  display: block !important;
} */

.paginationButton {
      margin-right: 10px;
      border: none;
      border-radius: 10px;
      padding: 2px 10px 2px 10px;
}
.modal-backdrop.show {
  /* opacity: var(--bs-backdrop-opacity); */
  /* display:te */
  opacity: 0 !important;
}
/* .deleteIocModal {
  background-color: #1d2835;
  opacity: .91;
} */
.deleteIocModal .modal-content {
  border-radius: 10px !important;
  background-color: #1d2835 !important;
  opacity: 1;
}
.mapAgentModal .modal-content {
  width: 250px !important;
  margin-left: auto;
  margin-right: auto;
  background: #00000052 !important;
  border-radius: 5px;

}
.mapAgentModal {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (min-width: 720px) {
  .mapAgentModal{
    /* position: "fixed"; */
    left: 25% !important
    
  } 
}

@media screen and (min-width: 1200px) {
  .mapAgentModal{
    /* position: "fixed"; */
    left: 35% !important
    
  } 
}

.logsGraphs{
  padding: 10px;
  border-left:1px solid;
}

@media screen and (max-width:992px) {
  .logsGraphs{
    border-top: 1px solid;
    border-left:none;
  }
}

.verify-email {
  width: 200px !important;
  margin: 10px auto;
}

.iocAction {
  cursor: pointer;
}
.iocUpload {
  font-size: 12px;
}
.iocDelete {
  font-size: 12px;
}
.modalHide {
  cursor: pointer;
  color: red;
  font-size: 15px;
}
.iocTableHeader {
  font-size: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.iocTableRow {
  font-size: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.iocTableCell{
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}

.customLink {
  color: var(--bs-theme);
  text-decoration: underline;
  cursor: pointer;
}

.customLink:hover{
  color: rgba(var(--bs-theme-rgb), 0.75);
}

.passwordEyeText {
  color: red;
  cursor: pointer;
  font-size: 16px;
}

.passwordEyePassword{
  color: var(--bs-theme);
  cursor: pointer;
  font-size: 16px;
}

.passwordEyeText:hover{
  opacity: .75;
}
.passwordEyePassword:hover{
  opacity: .75;
}


/* detail table details  */

.detailsTableModal .modal-content {
  background-color: #000000 !important;
}
.detailsTableModalLight .modal-content {
  background-color: #fff !important;
}

.detailTableDetails {
  /* background-color: black; */
  border: none !important;
}
.detailTableDetailsItems {
  border: none !important;
}
.detailTableDetailsItems h2 button {
  background-color: #353535 !important;
  padding: 8px !important;
  border: none !important;
}
.detailTableDetailsItemsLight h2 button {
  background-color: #c5c5c5 !important;
  padding: 8px !important;
  border: none !important;
}
.detailTableDetailsItems .accordion-button::after  {
  background-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
}

.detailTableDetailsItems .show{
  background-color: #252525 !important;
  border: none !important;

}

.detailTableView {
  color: var(--bs-theme);
  cursor: pointer;
  font-size: 20px;
}
