/*!
 * HUD REACT v2.0.0 (https://seantheme.com/hud-react/)
 * Copyright 2023 SeanTheme
 */

@import '_functions.scss';
@import '_variables.scss';
@import '_variables-dark.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import '_mixins.scss';

@import '_root.scss';
@import '_reboot.scss';
@import '_helper.scss';

@import '_app.scss';
@import '_layout.scss';
@import '_ui.scss';
@import '_plugins.scss';
@import '_pages.scss';
@import '_widgets.scss';
@import '_rtl.scss';


.custom-datepicker .react-datepicker__input-container input {
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
}

.agentPortDatePicker react-datepicker__input-container input {
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;
    height: 30px !important;
}

.remoteIp {
    color: var(--bs-theme);
    border-bottom:1px solid var(--bs-theme) ;
    cursor: pointer;
}
.maliciousIp {
    color: var(--bs-theme);
    cursor: pointer;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

.datePickerInput {
    text-align: center;
}

.map-container{
    height: 400px;
}

@media only screen and (max-width: 576px) {
    .map-container{
        height: 350px;
    }
}
// @media only screen and (min-width: 768px) {
//     .map-container{
//         height: 400px;
//     }
// }
// @media only screen and (min-width: 992px) {
//     .map-container{
//         height: 450px;
//     }
// }
// @media only screen and (min-width: 1240px) {
//     .map-container{
//         height: 570px;
//     }
// }

.apexcharts-pan-icon {
    display: none;
}
